// RESET
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*,
::before,
::after {
  border-width: 0;
  border-style: solid;
}

:root {
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
}
