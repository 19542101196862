// CONFIG GLOBAL
// RESET
// * {
//   box-sizing: border-box;
//   margin: 0;
//   padding: 0;
// }

// *,
// ::before,
// ::after {
//   border-width: 0;
//   border-style: solid;
// }

// :root {
//   font-size: 16px;
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-size: inherit;
//   font-weight: inherit;
// }

// ol,
// ul {
//   list-style: none;
//   margin: 0;
//   padding: 0;
// }

// img,
// svg,
// video,
// canvas,
// audio,
// iframe,
// embed,
// object {
//   display: block;
// }

// COLOR
$yellowColor: #ffbc25;
$whiteColor: #ffffff;
$grayColor: #838484;
$darkGrayColor: #636465;
$ligthGrayColor: #f2f2f2;
$blackColor: #333333;
$veryBlackColor: #282828;

// FONTS
$mainFont: "Inter", sans-serif;
$secondFont: "Montserrat", sans-serif;

// WIDTH
// $widthContainer: 1290px; // Fix
$widthContainer: 89%; // Desain Real
$heigtHeader: 6.497rem;

// SETUP MEDIA
@mixin mobile {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin tablet-portrait {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin tablet-landscape {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) {
    @content;
  }
}
@mixin big-desktop {
  @media (min-width: 1800px) {
    @content;
  }
}
