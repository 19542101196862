@import "../../global";

.globalleader {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10rem;

  &__container {
    width: $widthContainer;
    background: #fff7e4;
    border-radius: 20px;
    padding: 6.25rem 0;
    position: relative;
    overflow: hidden;

    .grafik__wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 26.875rem;

      @include mobile {
        top: -10rem;
        left: -5rem;
        width: 25rem;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .title {
      color: $blackColor;
      font-size: 3.125rem;
      font-weight: 700;
      text-align: center;
      letter-spacing: 0.3rem;
      margin-bottom: 3rem;

      @include mobile {
        line-height: 2.125rem;
        font-size: 1.5rem;
      }
    }

    .desc {
      margin: 0 auto;
      width: 38.75rem;
      font-size: 1rem;
      line-height: 1.625rem;
      text-align: center;
      letter-spacing: 0.04em;
      color: $grayColor;

      @include mobile {
        width: 100%;
        line-height: 1.5rem;
        font-size: 0.875rem;
      }
    }

    .tabs {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2.5rem;
      gap: 2.5rem;
      margin-bottom: 5.875rem;
      position: relative;
      z-index: 1;

      @include mobile {
        display: block;
      }

      .tab {
        padding: 0.75rem 2rem;
        border-radius: 6.25rem;
        text-transform: uppercase;
        border: 1px solid #838484;
        background: none;
        color: #838484;
        font-size: 0.875rem;
        font-weight: 700;
        letter-spacing: 0.1rem;
        transition: all 0.3s ease-out;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        // width: 20rem;

        @include mobile {
          margin: 1rem auto;
        }

        &:hover,
        &.active {
          background: #ffbc25;
          cursor: pointer;
          color: #333333;
          border: 1px solid #ffbc25;
        }

        a {
          text-decoration: none;
          color: inherit;
        }
      }
    }

    // Mens
    .mens {
      @include desktop {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
      }

      .mens__container {
        width: 25rem;
        height: 21.25rem;
        // background-color: cadetblue;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 4.5rem;

        @include mobile {
          width: 100%;
          margin: 2.5rem auto;
        }

        .image__wrapper {
          width: 14.25rem;
          height: 14.25rem;
          border-radius: 100%;
          border: 0.688rem solid rgba(255, 188, 37, 0.3);
          overflow: hidden;

          img {
            width: 100%;
            object-fit: cover;
            border: 0.3rem solid white;
            transition: 0.3s ease;

            &:hover {
              transform: scale(1.1);
              cursor: pointer;
            }
          }
        }

        .name {
          font-size: 1.75rem;
          font-weight: 700;
          color: $blackColor;
          text-transform: uppercase;
          text-align: center;

          @include mobile {
            font-size: 1.25rem;
          }
        }

        .jobs {
          font-size: 1rem;
          font-weight: 500;
          color: $grayColor;
          text-align: center;
        }
      }
    }
  }
}
