@import "../../global";
.detailpromo {
  width: 100%;
  margin-top: $heigtHeader;
  margin-bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    width: $widthContainer;
    padding: 5.125rem 0;

    .container__other {
      margin: 0 auto;
      width: 100%;

      @include desktop {
        width: 67%;
      }

      .detailpromo__title {
        font-weight: bold;
        font-size: 3.125rem;
        line-height: 5rem;
        letter-spacing: 0.02em;
        color: $blackColor;
        margin-bottom: 5rem;

        span {
          text-transform: uppercase;
        }

        @include mobile {
          font-size: 2.5rem;
          line-height: 3.4rem;
          margin-bottom: 3rem;
        }
      }

      .image__wrapper {
        width: 100%;
        height: 16.5rem;
        overflow: hidden;
        margin-bottom: 3rem;
        border-radius: 1.2rem;

        @include desktop {
          height: 35rem;
          // border-radius: 4.9rem;
          border-radius: 1.25rem;
          margin-bottom: 5rem;
        }

        img {
          width: 100%;
          height: 100%;
          // object-fit: cover;
          transition: all 0.3s ease;

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      p {
        font-weight: 500;
        font-size: 1rem;
        line-height: 2.1rem;
        color: rgba(51, 51, 51, 0.7);
        margin-bottom: 1.2rem;
        margin-top: 1.2rem;
      }

      .subtitle {
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 3.125rem;
        letter-spacing: 0.04em;
        color: $blackColor;
        margin-top: 5.625rem;
        margin-bottom: 2.5rem;
      }

      ol {
        li {
          display: list-item;
          list-style-type: decimal;
          list-style-position: outside;
          margin-left: 1.2rem;
          margin-bottom: -1rem;
          font-weight: 500;
          font-size: 1rem;
          line-height: 2.1rem;
          color: rgba(51, 51, 51, 0.7);
          white-space: inherit;
        }
      }

      // Order list by AZ
      ol.order__alpha {
        li {
          list-style-type: upper-alpha;
          display: list-item;
          list-style-position: outside;
          margin-left: 1.2rem;
          margin-bottom: -1rem;
          font-weight: 500;
          font-size: 1rem;
          line-height: 2.1rem;
          color: rgba(51, 51, 51, 0.7);
          white-space: inherit;
        }
      }

      ul.unorder {
        display: block;

        li {
          display: list-item;
          list-style-type: disc;
          list-style-position: outside;
          margin-left: 2.2rem;
          margin-bottom: -1rem;
          font-weight: 500;
          font-size: 1rem;
          line-height: 2.1rem;
          color: rgba(51, 51, 51, 0.7);
          white-space: inherit;
        }
      }

      .share__icons {
        // position: relative;
        display: flex;
        align-items: center;
        margin-top: 2.5rem;
        gap: 1.25rem;

        .share {
          font-weight: 500;
          font-size: 1rem;
          line-height: 2.1rem;
          color: rgba(51, 51, 51, 0.7);
        }

        .img__wrapper {
          background: #f2f2f2;
          border-radius: 4px;
          overflow: hidden;
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 50%;
            height: 50%;
            transition: all 0.3s ease;

            &:hover {
              cursor: pointer;
              transform: scale(1.2);
            }
          }
        }

        .toast__wrapper {
          // position: absolute;
          // bottom: 27rem;
          position: fixed;
          bottom: 2.5rem;
          left: 40%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $blackColor;
          // width: 13.438rem;
          width: 18.438rem;
          height: 2.5rem;
          padding: 0.5rem 2rem;
          border-radius: 0.25rem;
          gap: 1rem;
          // animation: 0.3s fadeIn;
          animation: topToBottom 0.4s ease;

          @include mobile {
            position: fixed;
            bottom: 2.5rem;
            left: 50%;
            transform: translateX(-50%);
            animation: topToBottom 0.3s ease;
          }

          @keyframes topToBottom {
            from {
              bottom: 5rem;
              opacity: 0;
            }

            to {
              bottom: 2.5rem;
              opacity: 1;
            }
          }

          .image_toast {
            display: block;
            width: 20px;
          }

          .text_toast {
            color: $whiteColor;
          }
        }
      }
    }
  }
}
