@import "../../global";

.cardpromotionlit {
  width: 100%;
  //   height: 26.25rem;
  overflow: hidden;
  margin-bottom: 3.125rem;

  @include desktop {
    // width: 18.125rem;
    width: 20.3vw;
    // height: 22.313rem;
  }

  .image__wrapper {
    width: 100%;
    height: 16.25rem;
    overflow: hidden;
    border-radius: 2rem;
    background-color: white;
    position: relative;

    @include desktop {
      height: 12.125rem;
      width: 100%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      // object-fit: cover;
      transition: all 0.3s ease;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .cardpromotion__details {
    width: 100%;
    padding: 0.3rem;
  }

  .expired__date {
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.01em;
    color: $grayColor;
    margin-bottom: 0.3rem;

    span {
      color: $yellowColor;
    }
  }

  .cardpromotion__title {
    font-weight: bold;
    font-size: 1.375rem;
    line-height: 2rem;
    letter-spacing: 0.02em;
    color: $blackColor;
    margin-bottom: 0.3rem;
    transition: all 0.3s ease;

    &:hover {
      cursor: pointer;
      color: $yellowColor;
    }
  }

  .cardpromotion__desc {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.01em;
    color: $darkGrayColor;
  }
}
