@import "../global";

.wrapper__loading {
  display: flex;
  align-items: center;
  gap: 1rem;

  .loader {
    border: 7px solid $ligthGrayColor;
    border-radius: 50%;
    border-top: 7px solid $grayColor;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-left: 0.5rem;
  }

  .title__loading {
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
