@import "../../global";
@import 'react-datepicker/dist/react-datepicker';

.corporate-governance {
  width: 100%;
  //   background-color: yellowgreen;
  border-radius: 1.25rem;
  overflow: hidden;

  @include desktop {
    margin-top: 4rem;
    display: flex;
  }

  .message {
    padding: 2.5rem;
    flex: 2;
    background-color: $blackColor;
    position: relative;

    @include mobile {
      margin-bottom: 2rem;
      border-radius: 1.25rem;
    }

    .batik {
      position: absolute;
      top: -9rem;
      right: -3rem;
      width: 20rem;
      transform: rotateY(180deg);

      @include mobile {
        width: 12rem;
        top: -4.5rem;
        right: -2rem;
      }

      img {
        width: 100%;
      }
    }

    .title-container {
      margin-bottom: 2.5rem;
    }

    .title {
      font-weight: bold;
      font-size: 2rem;
      letter-spacing: 0.04em;
      color: #ffffff;
    }

    form {
      .group__label {
        // display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        @include mobile {
          margin-bottom: 0.3rem;
        }

        @include desktop {
          display: flex;
        }

        .label {
          font-size: 1.25rem;
          line-height: 2.625rem;
          font-weight: 500;
          color: #ffffff;
        }
      }

      .group__control {
        // display: flex;
        justify-content: space-between;
        margin-bottom: 2.5rem;

        @include mobile {
          margin-bottom: 0;
        }

        @include desktop {
          display: flex;
        }

        input,
        textarea {
          // width: 22.625rem;
          width: 49%;
          height: 3.5rem;
          border: none;
          outline: none;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 0.75rem;
          padding: 1rem 1.25rem;
          color: rgba(255, 255, 255, 0.6);
          font-size: 1rem;
          font-weight: 500;

          @include mobile {
            width: 100%;
            margin-bottom: 2.5rem;
          }

          &::placeholder {
            color: rgba(255, 255, 255, 0.6);
            font-size: 1rem;
            font-weight: 500;
          }
          // Internet Explorer
          &:-ms-input-placeholder {
            color: rgba(255, 255, 255, 0.6);
            font-size: 1rem;
            font-weight: 500;
          }

          // Firefox 19+
          &::-moz-placeholder {
            color: rgba(255, 255, 255, 0.6);
            font-size: 1rem;
            font-weight: 500;
          }
        }

        .react-datepicker-wrapper {
          width: 50%;
          
          @include mobile {
            width: 100%;
            // margin-bottom: 2.5rem;
          }
        }
        .date-picker-wbs {
          width: 98%;
          // border: 3px rgba(255, 255, 255, 0.2);
          @include mobile {
            width: 100%;
          }
        }
        .success-wbs {
          border: 3px solid rgba(34, 255, 0, 0.2);
          width: 98%;
        }

        .error-wbs {
          border: 3px solid rgba(255, 0, 0, 0.2);
          width: 98%;
        }

        // Alert pada form
        .success {
          border: 3px solid rgba(34, 255, 0, 0.2);
        }

        .error {
          border: 3px solid rgba(255, 0, 0, 0.2);
        }

        textarea {
          width: 100%;
          height: 8rem;
          resize: none;
          font-family: Inter;
        }

        .wrapper__multi {
          width: 100%;
          height: 17rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 0.75rem;
          padding: 1rem 1.25rem;
          color: rgba(255, 255, 255, 0.6);
          font-size: 1rem;
          font-weight: 500;
          position: relative;
          z-index: 1;

          @include mobile {
            flex-wrap: wrap;
            margin-bottom: 2rem;
          }

          &::before {
            width: 96.5%;
            height: 87%;
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            border-radius: 0.75rem;
            border: 0.2rem dashed #7d7d7d;
            margin: 1.4%;
            z-index: -2;

            @include mobile {
              width: 94.5%;
              height: 94.5%;
            }
          }

          .img__wrapper {
            background: rgba(255, 255, 255, 0.2);
            border-radius: 0.75rem;
            padding: 1rem 1.25rem;

            .input__file {
              cursor: pointer;
              display: flex;
              align-items: center;
              gap: 0.5rem;
            }

            .text__file {
              font-size: 1rem;
              font-weight: 500;
              letter-spacing: 0.1rem;
            }
          }

          .text__wrapper {
            margin-left: 1rem;
          }

          .multi__file {
            display: none;
          }
        }

        .column {
          flex-direction: column;
          gap: 0.3rem;
        }

        .file__length {
          font-size: 1.4rem;
          font-weight: 700;
        }
      }

      // Group radio
      .group__radio {
        padding-left: 1.5rem;
        color: #ffffff;
        margin-bottom: 2rem;

        p {
          margin-bottom: 0.5rem;
        }

        .radio {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          input[type="radio"] {
            width: 1.5rem;
            height: 1.5rem;
            margin: 0.2rem 1rem 0.5rem 0rem;

            &:after {
              width: 1.3rem;
              height: 1.3rem;
              border-radius: 100%;
              background-color: $blackColor;
              content: "";
              display: inline-block;
              visibility: visible;
              border: 3px solid #ffffff;
            }

            &:checked:after {
              width: 1.3rem;
              height: 1.3rem;
              border-radius: 100%;
              background-color: $yellowColor;
              content: "";
              display: inline-block;
              visibility: visible;
              border: 3px solid #ffffff;
            }
          }
        }
      }

      .btn__wrapper {
        width: 100%;
        display: flex;
        justify-content: center;

        .btn__contact {
          width: 15rem;
          height: 4rem;
          background: $yellowColor;
          border-radius: 100px;
          font-size: 0.875rem;
          font-weight: bold;
          letter-spacing: 0.04em;
          text-transform: uppercase;
          transition: all 0.2s ease;

          &:hover {
            cursor: pointer;
            background-color: orange;
          }
        }
      }
    }
  }
  .contact {
    padding: 2.5rem;
    background-color: $yellowColor;
    flex: 1;
    position: relative;

    @include mobile {
      border-radius: 1.25rem;
    }

    .batik__contact {
      position: absolute;
      top: 14rem;
      right: -6rem;
      width: 20rem;

      @include mobile {
        width: 12rem;
        top: 13rem;
        right: 0rem;
      }

      img {
        width: 100%;
      }
    }

    .title {
      font-weight: bold;
      font-size: 2rem;
      letter-spacing: 0.04em;
      color: $blackColor;
      margin-bottom: 2.5rem;
    }

    .kontak {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: $blackColor;
      font-weight: bold;
      font-size: 1.25rem;
      letter-spacing: 0.02em;
      margin-bottom: 2.5rem;
    }
    .center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn__wrapper {
      position: absolute;
      bottom: 2rem;

      @include mobile {
        bottom: 0.2rem;
      }

      .btn__contact {
        width: 15rem;
        height: 4rem;
        background: $blackColor;
        color: $yellowColor;
        border-radius: 100px;
        font-size: 0.875rem;
        font-weight: bold;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        transition: all 0.2s ease;

        &:hover {
          cursor: pointer;
          background-color: rgb(19, 19, 19);
        }
      }
    }
  }
}
