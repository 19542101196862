@import "../../global";

.rate__limit {
  width: 100%;
  margin-top: $heigtHeader;
  margin-bottom: 10rem;

  &__tabs {
    width: 100%;

    .rate__container {
      width: $widthContainer;
      margin: 7.625rem auto;

      .rate__desc {
        overflow-x: auto;

        .title__rate {
          font-weight: 700;
          font-size: 3rem;
          text-align: center;
          margin-top: 1rem;
          margin-bottom: 5rem;
          letter-spacing: 4px;
        }

        p {
          font-size: 1rem;
          line-height: 1.625rem;
          text-align: justify;
          letter-spacing: 0.01em;
          // margin-top: 5rem;
          // margin-bottom: 1rem;
        }

        table {
          width: 100%;
          overflow: hidden;
          border-radius: 1.25rem;
          margin-bottom: 5rem;

          thead {
            background-color: $blackColor;
            height: 4.5rem;
            color: $whiteColor;
            font-size: 1.375rem;
            font-weight: 700;

            tr {
              th {
                padding: 1.25rem;
              }
            }

            @include mobile {
              font-size: 0.875rem;
            }
          }

          tbody tr {
            height: 4.5rem;
            color: $blackColor;
            font-size: 1rem;
            font-weight: 500;
            letter-spacing: 0.01rem;
            transition: all 0.3s linear;

            @include mobile {
              font-size: 0.7rem;
            }

            td {
              // border-right: 0.188rem solid $whiteColor;
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              line-height: 1.8rem;

              // &:nth-child(3) {
              //   background-color: $ligthGrayColor;
              //   text-align: center;
              // }

              @include mobile {
                padding-left: 0.5rem;
              }

              p {
                font-size: 1rem;

                @include mobile {
                  font-size: 0.7rem;
                }
              }

              ul {
                display: list-item;
                list-style-type: disc;
                list-style-position: outside;
                margin-left: 0.5rem;

                li {
                  white-space: pre-wrap;
                  line-height: 1.625rem;
                  color: $blackColor;
                  font-size: 1rem;
                  font-weight: 500;
                  letter-spacing: 0.01rem;
                  display: list-item;

                  @include mobile {
                    font-size: 0.7rem;
                  }
                }
              }
            }
          }

          tbody tr:nth-child(2n) {
            background-color: $ligthGrayColor;
          }

          tbody tr:nth-last-child(3) {
            background-color: $ligthGrayColor;
          }

          tbody tr:nth-last-child(2) {
            background-color: white;
          }

          tbody tr:last-child {
            background-color: $ligthGrayColor;
          }
        }

        ul {
          display: list-item;
          list-style-type: disc;
          list-style-position: outside;
          margin-left: 3.5rem;

          li {
            white-space: pre-wrap;
            line-height: 1.625rem;
          }
        }

        ol {
          // margin-top: 5rem;
          list-style-type: decimal;
          list-style-position: outside;
          margin-left: 2rem;

          li {
            // margin-left: 0.2rem;
            display: list-item;
            // font-weight: 700;
            // text-transform: uppercase;
            white-space: inherit;
            line-height: 1.625rem;

            ol {
              list-style-type: lower-alpha;
            }
          }
        }
      }
    }
  }
}
