@import "../../global.scss";

.allopoints {
  width: 100%;
  padding: 0 1rem;

  @include desktop {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10rem;
    padding: 0;
  }

  .allopoints-container {
    padding: 1rem;

    @include desktop {
      width: $widthContainer;
      // height: 44.577rem;
      // display: flex;
      // flex-direction: column;
      // padding: 6.188rem 8.125rem;
      // justify-content: space-between;
    }

    .allopoints-title {
      color: $blackColor;
      font-size: 3.125rem;
      font-weight: 700;
      text-align: center;
      letter-spacing: 0.3rem;
      margin-bottom: 3.75rem;

      span {
        color: $yellowColor;
      }
    }

    .allopoints-cards {
      background-color: white;
      border-radius: 1.25rem;
      padding: 3.75rem 1.813rem 2.5rem;
      box-shadow: 0px 2px 40px rgba(41, 69, 85, 0.1);

      @include desktop {
        width: 100%;
        height: 23.375rem;
        display: flex;
        justify-content: space-around;
      }

      .allopoints-card {
        width: 100%;
        // height: 15.25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: space-between;
        gap: 1.25rem;
        margin-bottom: 2rem;

        @include desktop {
          width: 30%;
          height: 15.25rem;

          &:nth-child(1)::after {
            content: "";
            position: absolute;
            // width: 26rem;
            width: 28%;
            height: 14rem;
            // border-left: 1px dashed rgba(51, 51, 51, 0.2);
            // border-right: 1px dashed rgba(51, 51, 51, 0.2);
          }
        }

        img {
          width: 6.25rem;
          height: 6.25rem;
        }

        h4 {
          font-size: 1.375rem;
          font-weight: 700;
          color: $blackColor;
          //   text-align: center;
        }

        p {
          font-size: 1rem;
          color: $grayColor;
          line-height: 1.875rem;
          text-align: center;
        }
      }
    }
  }
}
