@import "../../global";

.cardpromotionbig {
  width: 100%;
  //   height: 26.25rem;
  overflow: hidden;
  margin-bottom: 4.688rem;

  @include desktop {
    // width: 38.75rem;
    width: 43vw;
    // height: 45.25rem;
  }

  .image__wrapper {
    width: 100%;
    height: 16.25rem;
    overflow: hidden;
    border-radius: 2rem;
    background-color: white;
    position: relative;

    @include desktop {
      height: 25.75rem;
      width: 100%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      // object-fit: cover;
      transition: all 0.3s ease;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .cardpromotion__details {
    width: 100%;
    padding: 0.5rem;
  }

  .expired__date {
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.01em;
    color: $grayColor;
    margin-bottom: 0.5rem;

    span {
      color: $yellowColor;
    }
  }

  .cardpromotion__title {
    font-weight: bold;
    font-size: 3.125rem;
    line-height: 3.75rem;
    letter-spacing: 0.02em;
    color: $blackColor;
    margin-bottom: 0.5rem;
    transition: all 0.3s ease;

    @include mobile {
      line-height: 2.125rem;
      font-size: 1.5rem;
    }

    &:hover {
      cursor: pointer;
      color: $yellowColor;
    }
  }

  .cardpromotion__desc {
    font-weight: 500;
    font-size: 1rem;
    line-height: 2.125rem;
    letter-spacing: 0.01em;
    color: $darkGrayColor;
  }
}
