@import "../../global";

.card__press__release {
  width: 100%;
  margin-right: 1.5rem;
  margin-bottom: 3rem;
  flex-shrink: 0;

  @include desktop {
    margin-right: 2.5rem;
    // width: 25rem;
    // width: 31%;
    width: 29.55%;
  }

  .card__image__wrapper {
    // background-color: tomato;
    width: 100%;
    overflow: hidden;
    border-radius: 2rem;

    img {
      width: 100%;
      height: 15rem;
      transition: 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .card__contents {
    padding: 0 0.5rem;

    &:hover {
      cursor: pointer;
    }
    &:hover .card__title {
      color: $yellowColor;
    }

    .card__title {
      font-weight: bold;
      color: $blackColor;
      font-size: 1.375rem;
      line-height: 2rem;
      letter-spacing: 0.02em;
      margin-top: 0.75rem;
      transition: 0.3s ease;
    }

    .card__description {
      font-weight: 500;
      color: $grayColor;
      font-size: 0.875rem;
      line-height: 1.5rem;
      letter-spacing: 0.02em;
      margin-top: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .card__date {
      width: 9.5rem;
      height: 2.188rem;
      background: $ligthGrayColor;
      border-radius: 0.625rem;
      color: $blackColor;
      font-size: 0.875rem;
      line-height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
    }
  }
}
