@import "../../global";

.products__tabs__tables {
  width: $widthContainer;
  margin: 5rem auto;

  &__title {
    font-size: 3.125rem;
    font-weight: 700;
    color: $blackColor;
    letter-spacing: 0.1rem;
    line-height: 4.375rem;
    text-align: center;
    margin-bottom: 3.75rem;

    @include mobile {
      font-size: 1.5rem;
      line-height: 2.125rem;
    }
  }

  .dateUpdate {
    margin-top: 3rem;
    font-style: italic;
    font-size: .9rem;
    font-weight: 500;
    display: flex;
    color: $blackColor;
    justify-content: flex-end;
  }

  &__table {
    overflow: hidden;
    border-radius: 1.25rem;
    // background-color: blanchedalmond;

    .headings {
      background-color: $blackColor;
      height: 4.5rem;
      display: flex;
      align-items: center;
      color: $whiteColor;
      font-size: 1.375rem;
      font-weight: 700;

      @include mobile {
        font-size: 0.875rem;
      }

      &__plans {
        width: 50%;
        height: 100%;
        border-right: 0.188rem solid $whiteColor;
        display: flex;
        align-items: center;
        padding-left: 2.5rem;
      }
      &__allopay {
        width: 25%;
        height: 100%;
        border-right: 0.188rem solid $whiteColor;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__allopayplus {
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    // Background Dinamis
    .backGray {
      background-color: $ligthGrayColor;
    }

    .backWhite {
      background-color: $whiteColor;
    }

    .bodys {
      height: 4.5rem;
      display: flex;
      align-items: center;
      color: $blackColor;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 0.01rem;
      transition: all 0.3s linear;

      @include mobile {
        font-size: 0.7rem;
      }

      &:hover {
        background-color: rgba(255, 188, 37, 0.3);
      }

      &__plans {
        width: 50%;
        height: 100%;
        border-right: 0.188rem solid $whiteColor;
        display: flex;
        align-items: center;
        padding-left: 2.5rem;
      }
      &__allopay {
        width: 25%;
        height: 100%;
        border-right: 0.188rem solid $whiteColor;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__allopayplus {
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
