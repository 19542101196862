@import "../../global";
.promo {
  width: 100%;
  margin-top: $heigtHeader;
  // margin-bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    width: $widthContainer;
    // background-color: bisque;
    padding: 5.125rem 0;

    .promo__title {
      font-weight: bold;
      font-size: 5rem;
      letter-spacing: 0.02em;
      color: $blackColor;
      margin-bottom: 1.25rem;

      @include mobile {
        line-height: 3.8rem;
        font-size: 3rem;
      }
    }

    .promo__subtitle {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.875rem;
      color: rgba(51, 51, 51, 0.7);
    }

    // Cards
    .featured__cards {
      margin-top: 5.625rem;

      @include desktop {
        display: flex;
        justify-content: space-between;
        gap: 2.5rem;
      }

      .fourt__card {
        @include desktop {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 1rem;
        }
      }
    }

    .normal__cards {
      margin-top: 5.625rem;
      //   background-color: rgb(204, 200, 195);
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}
